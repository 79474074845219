<template>
  <b-card
    v-if="loading"
    class="bg-transparent"
    body-class="px-2 py-3"
    :style="borderRightStyle"
  >
    <div class="d-flex flex-row align-items-center">
      <div class="px-1 min-h-60px min-w-60px max-h-60px d-flex align-items-center justify-content-center">
        <i
          :class="['fad fa-spinner fa-spin mb-0', textVariantClass]"
          style="font-size: 45px;"
        />
      </div>
      <div class="flex-grow-1 d-flex flex-column">
        <div class="d-flex flex-row align-items-center px-1">
          <b-skeleton
            animation="wave"
            width="10%"
            :variant="variant"
            class="min-h-30px"
          />
          <b-skeleton
            animation="wave"
            width="40%"
            :variant="variant"
            class="min-h-20px align-self-end ml-1"
          />
        </div>
        <div class="px-1 mt-1">
          <b-skeleton
            animation="wave"
            width="40%"
            variant="light-dark"
            class="min-h-10px"
          />
        </div>
      </div>
    </div>
  </b-card>
  <b-card
    v-else
    class="bg-transparent"
    body-class="px-2 py-3"
    :style="borderRightStyle"
  >
    <div class="d-flex flex-row align-items-center">
      <div class="px-1 min-h-60px min-w-60px max-h-60px d-flex align-items-center justify-content-center">
        <i
          :class="['mb-0', iconClass, textVariantClass]"
          style="font-size: 45px;"
        />
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex flex-row align-items-center">
          <div
            :class="['h3 font-weight-bolder mb-0 px-1', textVariantClass]"
            v-text="animatedCounter"
          />
          <div
            :class="['h6 flex-grow-1 align-self-end', textVariantClass]"
            style="margin-bottom: 3px;"
            v-text="title"
          />
        </div>
        <div
          class="px-1 text-dark"
          style="font-size: 12px;"
          v-text="subTitle"
        />
      </div>
    </div>
  </b-card>
</template>
<script>
import gsap from 'gsap'

export default {
  name: 'Card',

  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    borderRight: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    subTitle: {
      type: String,
      default: () => ''
    },
    count: {
      type: [Number, String],
      default: () => 0
    },
    variant: {
      type: String,
      default: () => 'primary'
    },
    icon: {
      type: String,
      default: () => 'fad fa-file'
    }
  },

  data () {
    return {
      tweenedNumber: this.count
    }
  },

  computed: {
    textVariantClass () {
      if (this.variant) {
        return `text-${this.variant}`
      }
      return null
    },
    iconClass () {
      if (this.variant) {
        return this.icon
      }
      return null
    },
    borderVariantClass () {
      if (this.variant) {
        return `border-${this.variant}`
      }
      return null
    },
    borderRightStyle () {
      if (this.borderRight) {
        return {
          borderRight: '1px solid #dee2e6',
          borderRadius: '0px'
        }
      }

      return {
        borderRadius: '0px'
      }
    },

    animatedCounter () {
      return this.tweenedNumber.toFixed(0)
    }
  },

  watch: {
    'count' (value) {
      gsap.to(this.$data, { duration: 1, tweenedNumber: value })
    }
  }
}
</script>
